import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class NewsItemService {
  getItems(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/news_items?page=${page}&limit=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/news_items?page=${page}&limit=${limit}`, { headers: authHeader() });
    }
  }

  getNewsItem(id) {
    return axios.get(API_URL + `admin/news_items/${id}`, {
      headers: authHeader(),
    });
  }

  saveNewsItem(c, tags) {
    return axios.put(API_URL + `admin/news_items/${c.id}`, { ...c, tags: tags }, { headers: authHeader() });
  }

  async createNewsItem(data) {
    const newsItem = await axios.post(
      API_URL + `admin/news_items`,
      { ...data, published_at: new Date() },
      {
        headers: authHeader(),
      }
    );
    console.log(newsItem);
    return newsItem.data;
  }

  getNewsItemTags() {
    return axios.get(API_URL + `admin/tags`, {
      headers: authHeader(),
    });
  }

  getAuthors() {
    return axios.get(API_URL + `admin/news_items/users`, {
      headers: authHeader(),
    });
  }

  delete(news_item_id) {
    return axios.delete(API_URL + `admin/news_items/${news_item_id}`, {
      headers: authHeader(),
    });
  }

  // search index
  // updateUpdateIndex(item_id) {
  //   return axios.get(API_URL + `admin/news_items/reindex/${item_id}`, { headers: authHeader() });
  // }

  reindex() {
    return axios.get(API_URL + `admin/news_items/reindex`, { headers: authHeader() });
  }

}

export default new NewsItemService();
