<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Wijzig item" overview_route="/news_items" />

    <div class="mt-10 sm:mt-0" v-if="newsItem">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">General information</h3>
            <p class="mt-1 text-sm text-gray-600">
              Here you can set generic details
            </p>
          </div>
        </div>

        <div class="mt-5 md:mt-0 md:col-span-2">
          <NewsitemForm :newsitem="newsItem" />
          <button type="button" class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" @click="destroy">
            Delete
          </button>
        </div>
        
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Highlight image</h3>
            <p class="mt-1 text-sm text-gray-600">
              For social media. Upload a high resolution image. The image will be scaled automatically.<br><br>Make sure you only enable one image as social media image.
            </p>
          </div>
        </div>

        <div class="mt-5 md:mt-0 md:col-span-2">
          <div v-if="newsItem">
            
              <div class="mt-5 md:mt-0 md:col-span-2">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                  <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div>
                      <Uploader model="Newsitem" :model_id="newsItem.id" @update-add="updateAttachments" />  
                      <PhotoList model="Newsitem" :photos="newsItem.Photos" :id="newsItem.id" @update-delete="updateAttachments" />
                    </div>
                  </div>    
                </div>
              </div>
            
          </div>
        </div>
          
      </div>
    </div>

  </main>
</template>

<script>
import NewsItemService from '../../../services/admin/news_items/service';
import PhotoList from '../../../components/admin/generic/PhotoList';
import Uploader from '../../../components/admin/generic/Uploader';
import NewsitemForm from '../../../components/admin/news_items/NewsitemForm';

export default {
  name: 'NewsItemEdit',
  components: {
    PhotoList,
    Uploader,
    NewsitemForm
  },
  data() {
    return {
      newsItem: null,
      loading: true,
      currentLocale: 'en',
      currentId: this.$route.params.id
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      return NewsItemService.getNewsItem(this.currentId).then(res => {
        if (res.status !== 200) {
          const error = new Error(res.statusText);
          throw error;
        }
        return res.data;
      })
      .then(json => {
        // set the response data
        this.newsItem = json;
        
      })
      .catch(err => {
        this.error = err;
        // In case a custom JSON error response was provided
        if (err.json) {
          return err.json.then(json => {
            // set the JSON response message
            this.error.message = json.message;
          });
        }
      })
      .then(() => {
        this.loading = false;
      });
    
    },
    async destroy() {
      if(confirm('are you sure?')) {
        this.loading = true;
        await NewsItemService.delete(this.newsItem.id);
        this.$router.push(`/news_items/`);
        this.loading = false;
      }
    },
    async updateAttachments() {
      // todo update attachments only?
      const data = await NewsItemService.getNewsItem(this.$route.params.id);
      this.newsItem = data.data;
    }
  },
  mounted() {
    this.fetchData()
  },
}
</script>
